<template>
  <div class="header">
    Checkin QR Code
  </div>
</template>

<script>
export default {
name:'HeaderComponent',
}
</script>

<style>
.header{
    width: 100%;
    padding: 10px;
    font-size: 23px;
    font-weight: 500;
    text-align: center;
}
</style>