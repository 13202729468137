<template>
  <div class="home d-flex justify-content-center align-items-center">
    <div
      v-if="!isLogin"
      class="form-data d-flex flex-column align-items-center"
    >
      <div>
        Mật khẩu
        <input v-model="passWord" type="password" id="pwd" name="pwd" />
        <div v-if="messErrorPassWord" class="errorPassWord">
          {{ messErrorPassWord }}
        </div>
      </div>
      <button
        type="button"
        class="btn-scan btn btn-primary"
        @click="checkPassword"
        :disabled="!passWord"
      >
        Đăng nhập
      </button>
    </div>
    <div
      v-else
      class="d-flex align-items-center justify-content-center"
      style="width: 100%"
    >
      <div
        v-if="!openCamera"
        class="form-data d-flex flex-column align-items-center"
      >
        QRCode
        <input v-model="qrCode" v-on:keyup="enterQRCode" />
        <button
          type="button"
          class="btn-scan btn btn-primary mb-20"
          @click="checkQRCode"
          :disabled="!qrCode"
          style="margin: 20px 0"
        >
          Xác nhận
        </button>
        <button
          type="button"
          class="btn-scan btn btn-outline-dark"
          @click="
            () => {
              openCamera = true;
            }
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-camera"
            viewBox="0 0 16 16"
          >
            <path
              d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"
            />
            <path
              d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"
            />
          </svg>
          Quét QRCode
        </button>
      </div>
      <div v-if="openCamera">
        <ScannerCamera
          @closeCamera="
            () => {
              openCamera = false;
            }
          "
        />
      </div>
    </div>
    <ModalComponent
      :showModal="showModal"
      @closeModal="closeModal"
      :isCheckin="isCheckin"
      :information="dataCustomer"
      :message="messageChecking"
    >
    </ModalComponent>
  </div>
</template>

<script>
// @ is an alias to /src
import "./style.scss";
import router from "@/router";
import ModalComponent from "@/components/ModalComponent.vue";
import ScannerCamera from "./AboutView.vue";
export default {
  name: "HomeView",
  components: { ModalComponent, ScannerCamera },
  data() {
    return {
      passWord: "",
      qrCode: "",
      isLogin: false,
      showModal: false,
      isCheckin: false,
      dataCustomer: null,
      messageChecking: "",
      openCamera: false,
      messErrorPassWord: "",
    };
  },
  async mounted() {
    // await this.$store.dispatch("getListSales");
    // const listSales = this.$store.state.listSales;
    const checkLogin = localStorage.getItem("isLogin");
    if (checkLogin) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  },
  watch: {
    passWord(value) {
      this.messErrorPassWord = "";
    },
  },
  methods: {
    enterQRCode(e) {
      if (e.keyCode === 13) {
        this.checkQRCode();
      }
    },
    checkPassword() {
      if (this.passWord === "123mkg") {
        localStorage.setItem("isLogin", true);
        this.isLogin = true;
      } else {
        this.messErrorPassWord = "Mật khẩu không chính xác!";
      }
    },
    async checkQRCode() {
      await this.$store.dispatch("getDataCustomer", { qrcode: this.qrCode });
      if (!this.$store.state.error) {
        this.showModal = true;
        this.messageChecking = this.$store.state.message;
        if (this.$store.state.dataCustomer) {
          this.dataCustomer = this.$store.state.dataCustomer;
        } else {
          this.dataCustomer = null
          this.isCheckin = false;
        }
      }
    },
    closeModal() {
      this.showModal = false;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
