import Vue from "vue";
import Vuex from "vuex";
import CustomerAPI from "@/api/customer";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dataCustomer: null,
    error: false,
    app_code:'',
    message:''
  },
  getters: {},
  mutations: {
    setDataCustomer(state, data) {
      state.dataCustomer = data;
    },
    setError(state, data) {
      state.error = data;
    },
    setAppCode(state, data) {
      state.app_code = data;
    },
    setMessage(state, data) {
      state.message = data;
    },
  },
  actions: {
    async getDataCustomer({ commit }, customer_id) {
      commit("setDataCustomer", null);
      commit('setAppCode','');
      commit('setMessage','');

      await CustomerAPI.checkQRCodeCustomer(customer_id)
        .then(({ data }) => {
          commit('setAppCode',data.app_code)
          commit('setMessage',data.message)
          commit("setError", false);
          if(data.success){
            commit("setDataCustomer", data.data);
          }

        })
        .catch((error) => {
          commit("setError", true);
        });
    },
  },
  modules: {},
});
