<template>
  <div class="home d-flex justify-content-center align-items-center">
    <!-- <div v-if="!showModal"> -->
    <div v-if="!loading" class="btn-closed" @click="closeCamera">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="#fff"
        class="bi bi-x"
        viewBox="0 0 16 16"
      >
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </div>
    <StreamBarcodeReader
      @decode="onDecode"
      @loaded="onLoaded"
    ></StreamBarcodeReader>
    <!-- </div> -->
    <div v-if="loading">loading camera</div>
    <ModalComponent
      :showModal="showModal"
      @closeModal="closeModal"
      :isCheckin="isCheckin"
      :information="dataCustomer"
      :message="messageChecking"
    >
    </ModalComponent>
  </div>
</template>

<script>
// @ is an alias to /src
import "./style.scss";
import { StreamBarcodeReader } from "vue-barcode-reader";
import router from "@/router";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: "ScannerCamera",
  components: {
    StreamBarcodeReader,
    ModalComponent,
  },
  data() {
    return {
      qrCode: "",
      loading: true,
      showModal: false,
      isCheckin: false,
      dataCustomer: null,
      messageChecking: "",
      id: null,
    };
  },
  watch: {
    qrCode: function (val, oldVal) {
      if (val) {
        this.checkQRCode(val); // call it in the context of your component object
      }
    },
  },
  methods: {
    onDecode(result) {
      if (!this.showModal) {
        this.qrCode = result;
        if (this.id) clearTimeout(this.id);
        this.id = setTimeout(() => {
          if (this.qrCode === result) {
            this.qrCode = "";
          }
        }, 10000);
      }
    },
    onLoaded() {
      this.loading = false;
    },
    closeModal() {
      // console.log("vo day khong");
      // this.$emit("closeCamera");
      this.qrCode = "";
      this.showModal = false;
    },
    async checkQRCode(val) {
      await this.$store.dispatch("getDataCustomer", { qrcode: val });
      if (!this.$store.state.error) {
        this.showModal = true;
        this.messageChecking = this.$store.state.message;
        if (this.$store.state.dataCustomer) {
          this.dataCustomer = this.$store.state.dataCustomer;
        } else {
          this.dataCustomer = null;
          this.isCheckin = false;
        }
      }
    },
    closeCamera() {
      window.location.reload();
      // this.$emit("closeCamera");
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
}
.btn-closed {
  position: absolute;
  right: 5px;
  top: 55px;
  z-index: 10;
}
</style>
