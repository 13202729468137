<template>
  <div v-if="checkShowModal" class="modal">
    <div
      v-if="information"
      v-click-outside="closeModalFunc"
      class="modal__info"
    >
      <div class="header_modal">
        <div @click="closeModalFunc">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
      </div>
      <div class="info-title">Thông tin</div>
      <div class="info">
        <div class="item" v-for="(e, index) in info" :key="index">
          <div v-if="e.value" class="title d-flex">
            <div class="icon" v-html="e.icon"></div>
            <span class="text">{{ e.title }}:</span>
          </div>
          <div v-if="e.value" class="value">{{ e.value }}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-click-outside="closeModalFunc" class="modal__info">
        <div class="header_modal">
          <div @click="closeModalFunc">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>

        <div>{{ message }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import "../views/style.scss";

import Vue from "vue";
Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    window.event = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", window.event);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", window.event);
  },
});
export default {
  name: "ModalNotification",
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: "",
    },
    information: {
      type: Object,
      default: null,
    },
    isCheckin: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShowModal: this.showModal,
      info: [
        {
          title: "Họ và Tên",
          value: "Nguyen Van A",
          key: "full_name",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
</svg>`,
        },
        {
          title: "Số điện thoại",
          value: "0862481722",
          key: "phone_number",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
</svg>`,
        },
        {
          title: "Email",
          value: "nguyenvana@gmail.com",
          key: "email",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
</svg>`,
        },
        {
          title: "Địa chỉ",
          value: "Hồ Chí Minh",
          key: "address",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-house-door" viewBox="0 0 16 16">
  <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z"/>
</svg>`,
        },
        {
          title: "Nơi công tác",
          value: "Hồ Chí Minh",
          key: "workplace",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z"/>
</svg>`,
        },
        {
          title: "Tên sự kiện",
          value: "Pham Van B",
          key: "partner",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-person-badge" viewBox="0 0 16 16">
  <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
  <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z"/>
</svg>`,
        },
        {
          title: "Vị trí chỗ ngồi",
          value: "",
          key: "seat",
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pin-map" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"/>
  <path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"/>
</svg>`,
        },
      ],
    };
  },
  computed: {
    checkShowModal: {
      get() {
        return this.isShowModal;
      },
      set(newVal) {
        this.isShowModal = newVal;
      },
    },
    dataCustomer() {
      return this.$store.state.dataCustomer;
    },
  },
  watch: {
    showModal: function (newVal) {
      this.isShowModal = newVal;
    },
    information: function (newVal) {
      if (newVal) {
        this.info.forEach((item) => {
          if (item.key == "full_name") {
            item.value = newVal.full_name;
          } else if (item.key == "phone_number") {
            item.value = newVal.phone_number;
          } else if (item.key == "email") {
            item.value = newVal.email;
          } else if (item.key == "address") {
            item.value = newVal.customer_notes.address;
          } else if (item.key == "workplace") {
            item.value = newVal.customer_notes.workplace;
          } else if (item.key == "partner") {
            item.value = newVal.event.code + " - " + newVal.event.name;
          } else if (item.key == "seat") {
            item.value = newVal.customer_notes.note
              ? newVal.customer_notes.note
              : "";
          }
        });
      }
    },
  },
  methods: {
    closeModalFunc() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style lang="scss">
.modal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 10;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(36, 34, 34, 0.392);
  overflow: hidden;
  &__info {
    padding: 15px;

    width: 90%;
    height: auto;
    background-color: #fff;
    font-size: 16px;
    // font-family: "iCiel panton black";
    color: #000;
    text-align: center;
    border-radius: 5px;
    min-width: 350px;
    max-width: 450px;

    // border: 1px solid #00548e;
    .content {
      text-align: start;
    }
    .footer_modal {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      button {
        padding: 2px 10px;
        // border: 1px solid #00548e;
        // border-radius: 4px;
        font-size: 18px;

        background: #fff;
        color: #00548e;
        border: none;
      }
    }
    .header_modal {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 20px;
    }
  }
}
</style>
